<template>
  <section class="course-reviews" v-if="course">
    <div class="container course-reviews__container">
      <div class="course-reviews__header">
        <h2 class="course-reviews__title">Отзывы</h2>
        <!--        <button class="btn btn&#45;&#45;gray-outline btn&#45;&#45;medium">-->
        <!--          <span>Все отзывы</span>-->
        <!--          <ArrowRightIcon />-->
        <!--        </button>-->
      </div>
      <div class="course-reviews__list" v-if="course.reviews.length">
        <swiper ref="swiper" :options="swiperOptions">
          <swiper-slide v-for="(review, i) in course.reviews" :key="i">
            <div class="review">
              <div class="review__header">
                <img
                  :src="review.user.head_img | image($store.state._env.MEDIA_ENDPOINT)"
                  :alt="review.user.head_img | image_alt"
                  class="review__image"
                />
                <div class="review__header-info">
                  <span class="review__title">{{ review.user.surname }} {{ review.user.name }}</span>
                  <span class="review__subtitle">{{ course.title }}</span>
                </div>
              </div>
              <p class="review__text">{{ review.text }}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="review-stats">
        <div class="review-stats__info">
          <span class="review-stats__count">
            {{ course.reviews_avg_mark ? course.reviews_avg_mark.toFixed(1) : "-" }}
          </span>
          <div class="review-stats__rating-stars" v-if="course.reviews_avg_mark">
            <StarIcon
              v-for="i in 5"
              :key="i"
              :class="{ inactive: course.reviews_avg_mark.toFixed(1) < i - 0.5 }"
            />
          </div>
          <span class="review-stats__rating-reviews">
            ( {{ course.reviews.length | plural("оценка", "оценки", "оценок") }} )
          </span>
        </div>
        <div class="review-stats__stars-progress">
          <div class="review-stats__progress" v-for="i in 5" :key="i">
            <div class="review-stats__progress-line">
              <span :style="`max-width: ${getReviewWidth(course, i)}%`"></span>
            </div>
            <div class="review-stats__rating-stars">
              <StarIcon :class="{ inactive: i >= 6 - j + 1 }" v-for="j in 5" :key="j" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { directive, Swiper, SwiperSlide } from "vue-awesome-swiper";
import ArrowRightIcon from "components/svg/ArrowRightIcon.vue";
import StarIcon from "components/svg/StarIcon.vue";

export default {
  name: "CourseReviewsComponent",
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1.05,
        spaceBetween: -20,
        loop: false,
        clickable: true,
        breakpoints: {
          440: {
            slidesPerView: 1.2,
            spaceBetween: -20,
          },
          500: {
            slidesPerView: 1.4,
            spaceBetween: -20,
          },
          640: {
            slidesPerView: 1.6,
            spaceBetween: 20,
          },
          700: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          890: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          1097: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        },
      },
    };
  },
  computed: {
    course() {
      return this.$store.state.course_page.course;
    },
  },
  methods: {
    getReviewWidth(course, index) {
      let count = 0;
      switch (index) {
        case 1:
          count = course.reviews_five_marks_count;
          break;
        case 2:
          count = course.reviews_four_marks_count;
          break;
        case 3:
          count = course.reviews_three_marks_count;
          break;
        case 4:
          count = course.reviews_two_marks_count;
          break;
        case 5:
          count = course.reviews_one_marks_count;
          break;
      }
      return (count * 100) / 5;
    },
  },
  directives: {
    swiper: directive,
  },
  components: {
    StarIcon,
    ArrowRightIcon,
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/elements/swiper.styl"
@import "~@/styles/mixins/svg.styl"
.course-reviews {
  padding 0 32px
  +below(640px) {
    padding 0 20px
  }

  &__container {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    width 100%
    gap 50px
    +below(640px) {
      gap 10px
    }
  }

  &__header {
    display flex
    align-items center
    justify-content space-between
    width 100%
    gap 20px

    .btn {
      font-weight: normal;
      +below(640px) {
        display none
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 3em;
    line-height: 58px;
    margin 0
    +below(1120px) {
      font-size: 2.75em
      line-height: normal;
    }
    +below(540px) {
      font-size: 1.875em
      line-height: 36px;
    }
  }

  &__list {
    display flex
    width 100%
    justify-content stretch
    align-items flex-start
    +below(640px) {
      margin 0 -20px
      width calc(100% + 40px)
    }

    .swiper-container {
      width 100%
    }
  }

  .review-stats {
    +below(640px) {
      display none
    }
  }
}

.review {
  background: var(--white);
  border: 1px solid var(--gray_divider);
  box-sizing: border-box;
  border-radius: 15px;
  padding 30px
  display flex
  flex-direction column
  align-items flex-start
  justify-content flex-start
  gap 15px
  margin-bottom 1px
  +below(640px) {
    margin 20px
  }

  &__header {
    display: flex;
    align-items center
    justify-content flex-start
    gap 10px


    &-info {
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      gap 5px
    }
  }

  &__image {
    width 60px
    height 60px
    object-fit contain
    object-position center
    border-radius 100%
  }

  &__title {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 22px;
  }

  &__subtitle {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 17px;
    color var(--black)
    opacity: 0.5;
  }

  &__text {
    width 100%
    font-weight: normal;
    font-size: 0.875em;
    line-height: 140%;
  }
}

.review-stats {
  display grid
  grid-template-columns 155px 1fr
  grid-gap 30px
  align-items center
  justify-content start
  width 100%

  &__info {
    width 100%
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 10px
  }

  &__count {
    font-weight: bold;
    font-size: 6em;
    line-height: 116px;
  }

  &__rating {
    display flex
    justify-content center
    align-items center
    gap 10px
    flex-wrap wrap

    &-number {
      font-weight: bold;
      font-size: 0.875em;
      line-height: 17px;
    }

    &-stars {
      display flex
      align-items center
      gap 5px

      .icon {
        flex-shrink 0
        width 20px
        height 20px

        &.inactive svg {
          svg(var(--gray))
        }

        svg {
          width 100%
          height 100%
          svg(var(--orange))
        }
      }
    }

    &-reviews {
      font-size: 0.875em;
      line-height: 17px;
    }
  }

  &__stars-progress {
    display flex
    flex-direction column
    align-items flex-start
    justify-content center
    gap 22px
    max-width 853px
    width 100%
  }

  &__progress {
    display grid
    align-items center
    justify-content start
    grid-gap 8px
    grid-template-columns 1fr auto
    width 100%

    &-line {
      width 100%
      height 13px
      background var(--gray_light)
      display flex
      align-items stretch
      justify-content flex-start

      span {
        background var(--orange)
        height 100%
        width 100%
      }
    }
  }
}
</style>
