<template>
  <section class="course-about" v-if="course">
    <div class="container course-about__container">
      <div class="course-about__image">
        <h2 class="course-about__title">О школе</h2>
        <img
          :src="course.splash_img | image($store.state._env.MEDIA_ENDPOINT)"
          :alt="course.splash_img | image_alt"
        />
      </div>
      <div class="course-about__content">
        <div class="course-about__author">
          <img
            :src="school.head_img | image($store.state._env.MEDIA_ENDPOINT)"
            class="course-about__author-image"
            :alt="school.head_img | image_alt"
          />
          <div class="course-about__author-name">{{ school.title }}</div>
        </div>
        <div class="course-about__description">
          <EditorJSComponent
            v-if="school.about"
            :text="typeof school.about === 'object' ? JSON.parse(school.about) : ''"
          />
        </div>
        <ul class="course-about__stats">
          <li class="course-about__stat">
            <b>{{ school.students_count }}</b>
            <span>{{ school.students_count | plural_word("Студент", "Студента", "Студентов") }}</span>
          </li>
          <li class="course-about__stat">
            <b>{{ school.courses_count }}</b>
            <span>{{ school.courses_count | plural_word("Курс", "Курса", "Курсов") }}</span>
          </li>
          <li class="course-about__stat">
            <b>{{ school.teachers_count }}</b>
            <span>{{ school.teachers_count | plural_word("Лектор", "Лектора", "Лекторов") }}</span>
          </li>
        </ul>
        <div class="course-about__bottom">
          <router-link
            :to="{ name: 'school', params: { link: school.link } }"
            class="btn btn--main btn--medium"
            type="button"
          >
            Все курсы школы
          </router-link>
          <div class="course-about__rating" v-if="school.reviews_avg_mark">
            <span class="course-about__rating-number">
              {{ school.reviews_avg_mark.toFixed(1) || "-" }}
            </span>
            <div class="course-about__rating-stars" v-if="school.reviews_avg_mark">
              <StarIcon
                v-for="i in 5"
                :key="i"
                :class="{ inactive: school.reviews_avg_mark.toFixed(1) < i - 0.5 }"
              />
            </div>
            <span class="course-about__rating-reviews">
              ( {{ school.reviews.length | plural("оценка", "оценки", "оценок") }} )
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import StarIcon from "components/svg/StarIcon.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "CourseSchoolAboutComponent",
  computed: {
    school() {
      return this.course.school;
    },
    course() {
      return this.$store.state.course_page.course;
    },
  },
  components: { EditorJSComponent, StarIcon },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.course-about {
  background var(--gray_light)
  padding 0 32px
  overflow hidden
  +below(640px) {
    padding 0 20px
  }

  &__container {
    padding 100px 0
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 20px
    +below(1050px) {
      grid-template-columns 1fr
      padding 50px 0
      grid-gap 30px
    }
    +below(640px) {
      padding 20px 0
    }
  }

  &__image {
    width 100%
    height 100%
    position: relative;
    display flex
    align-items flex-start
    justify-content flex-start

    img {
      absolute right 91px top 64px
      max-width 700px
      width 100%
      height auto
      flex-shrink 0
      +below(1440px) {
        absolute right top
      }
      +below(1050px) {
        display none
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 3em;
    line-height: 58px;
    margin 0
    +below(1120px) {
      font-size: 2.75em
      line-height: normal;
    }
    +below(640px) {
      font-size: 1.875em
      line-height: 36px;
    }
  }

  &__author {
    display flex
    align-items center
    gap 15px

    &-image {
      width 80px
      height: 80px
      object-fit contain
      object-position center
      border-radius 50%
      background var(--black)
    }

    &-name {
      font-weight: 500;
      font-size: 1.5em
      line-height: 29px
      +below(640px) {
        font-size: 1.125em
        line-height: 22px
      }
    }
  }

  &__description {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 140%;
    margin 28px 0 33px
    max-width 588px
    +below(640px) {
      margin 20px 0
      font-size: 1em
      line-height: 22px
    }
  }

  &__stats {
    display flex
    flex-wrap wrap
    gap 40px
    margin-bottom 40px
    +below(640px) {
      gap 20px
      margin-bottom 20px
    }
  }

  &__stat {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 10px
    box-sizing border-box
    padding-bottom 20px
    border-bottom 1px solid var(--border_color)
    +below(640px) {
      padding-bottom 0
      border none
      gap 0
    }

    b {
      font-weight: bold;
      font-size: 3.75em;
      line-height: 73px;
      +below(640px) {
        font-size: 1.5em;
        line-height: 29px;
      }
    }

    span {
      font-weight: normal;
      font-size: 1.5em;
      line-height: 140%;
      +below(640px) {
        font-size: 0.875em;
        line-height: 140%;
      }
    }
  }

  &__bottom {
    display flex
    align-items center
    gap 20px
    +below(640px) {
      align-items flex-start
      flex-direction column-reverse
      width 100%
    }

    .btn {
      white-space nowrap
      padding 20px 50px
      +below(640px) {
        width 100%
      }
    }
  }

  &__rating {
    display flex
    justify-content center
    align-items center
    gap 10px
    flex-wrap wrap

    &-number {
      font-weight: bold;
      font-size: 0.875em;
      line-height: 17px;
    }

    &-stars {
      display flex
      align-items center
      gap 5px

      .icon {
        flex-shrink 0
        width 20px
        height 20px

        &.inactive svg {
          svg(var(--gray))
        }

        svg {
          width 100%
          height 100%
          svg(var(--orange))
        }
      }
    }

    &-reviews {
      font-size: 0.875em;
      line-height: 17px;
    }
  }
}
</style>
