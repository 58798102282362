<template>
  <main class="main course-open">
    <CourseWelcomeComponent />
    <CourseBenefitsComponent />
    <CourseProgramComponent />
    <CourseForComponentComponent />
    <CourseRequirementsComponent />
    <CourseSchoolAboutComponent />
    <CourseReviewsComponent />
    <CourseRecommendedComponent />
  </main>
</template>

<script>
import CourseWelcomeComponent from "./components/CourseWelcome.vue";
import CourseProgramComponent from "./components/CourseProgram.vue";
import CourseSchoolAboutComponent from "./components/CourseSchoolAbout.vue";
import CourseReviewsComponent from "./components/CourseReviews.vue";
import CourseBenefitsComponent from "./components/CourseBenefits.vue";
import CourseForComponentComponent from "./components/CourseFor.vue";
import CourseRequirementsComponent from "./components/CourseRequirements.vue";
import CourseRecommendedComponent from "./components/CourseRecommended.vue";

export default {
  name: "CourseOpenPage",
  components: {
    CourseRecommendedComponent,
    CourseRequirementsComponent,
    CourseForComponentComponent,
    CourseBenefitsComponent,
    CourseWelcomeComponent,
    CourseProgramComponent,
    CourseSchoolAboutComponent,
    CourseReviewsComponent,
  },
};
</script>

<style lang="stylus">
.course-open {
  display grid
  grid-gap 130px
  background var(--white)
  padding-bottom 130px
  +below(1240px) {
    grid-gap 80px
    padding-bottom 80px
  }
  +below(640px) {
    grid-gap 50px
    padding-bottom 50px
  }
}
</style>
