<template>
  <section class="course-for" v-if="course && course_for.length">
    <div class="container course-for__container">
      <h2 class="course-for__title">Для кого этот курс?</h2>
      <ul class="course-for__list">
        <li class="course-for__item" v-for="(item, i) in course_for" :key="i">
          <figure class="icon" v-html="item.icon"></figure>
          <h3 class="course-for__item-title">{{ item.title }}</h3>
          <p class="course-for__item-text">{{ item.text }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "CourseForComponent",
  computed: {
    course_for() {
      return this.course.additionals.filter((additional) => {
        return additional.type.code === this.$store.state._types.CODE_ADDITIONALS_FOR_WHOM;
      });
    },
    course() {
      return this.$store.state.course_page.course;
    },
  },
};
</script>

<style lang="stylus">
.course-for {
  padding 0 32px
  +below(640px) {
    padding 0 20px
  }

  &__container {
    display grid
    grid-gap 50px
  }

  &__title {
    font-weight: bold;
    font-size: 3em;
    line-height: 58px;
    margin 0
    +below(1120px) {
      font-size: 2.75em
      line-height: normal;
    }
    +below(540px) {
      font-size: 1.875em
      line-height: 36px;
    }
  }

  &__list {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 20px
    +below(1090px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(860px) {
      grid-template-columns 1fr
      justify-content center
    }
  }

  &__item {
    display flex
    flex-direction column
    align-items center
    justify-content center
    +below(540px) {
      align-items flex-start
    }

    .icon {
      height 200px
      width auto

      svg {
        width 100%
        height 100%
      }
    }

    &-title {
      margin 32px 0 12px
      font-weight: bold;
      font-size: 1.375em;
      line-height: 34px;
      text-align: center;
      max-width 360px
      +below(768px) {
        max-width 100%
      }
    }

    &-text {
      font-weight: normal;
      font-size: 1.25em;
      line-height: 34px;
      text-align: center;
      max-width 360px
      +below(768px) {
        max-width 100%
      }
      +below(540px) {
        text-align left
      }
    }
  }
}
</style>
