<template>
  <section class="course-for" v-if="course && requirements.length">
    <div class="container course-for__container">
      <h2 class="course-for__title">Требования</h2>
      <ul class="course-for__list">
        <li class="course-bnf__item" v-for="(requirement, i) in requirements" :key="i">
          <ConfirmedIcon />
          <span>{{ requirement.title }}</span>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import ConfirmedIcon from "components/svg/ConfirmedIcon.vue";

export default {
  name: "CourseRequirementsComponent",
  computed: {
    requirements() {
      return this.course.additionals.filter((additional) => {
        return additional.type.code === this.$store.state._types.CODE_ADDITIONALS_REQUIREMENTS;
      });
    },
    course() {
      return this.$store.state.course_page.course;
    },
  },
  components: { ConfirmedIcon },
};
</script>
