<template>
  <figure class="icon">
    <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9997 1V21M21 10.6663H1"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CrosshairIcon",
};
</script>
