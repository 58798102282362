<template>
  <figure class="icon">
    <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
      <path
        clip-rule="evenodd"
        d="M10.6371 21.2747C16.5119 21.2747 21.2742 16.5122 21.2742 10.6373H18.9104C18.9104 15.2067 15.2063 18.9108 10.6371 18.9108C6.06789 18.9108 2.3638 15.2067 2.3638 10.6373C2.3638 6.06802 6.06789 2.36385 10.6371 2.36385C11.9511 2.36385 13.1937 2.67022 14.2971 3.21542L15.8736 1.37612C14.3277 0.500111 12.5408 0 10.6371 0C4.7624 0 0 4.7625 0 10.6373C0 16.5122 4.7624 21.2747 10.6371 21.2747Z"
        fill="#E5E5E5"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M18.6067 6.70013C19.0434 6.21494 19.004 5.46762 18.5189 5.03095C18.0337 4.59428 17.2864 4.63362 16.8497 5.11881L10.2398 12.4633L7.92952 10.1379C7.46945 9.67483 6.72112 9.6724 6.25807 10.1325C5.79501 10.5925 5.79259 11.3409 6.25264 11.804L9.44383 15.016C9.67282 15.2465 9.98661 15.3726 10.3114 15.3645C10.6362 15.3565 10.9434 15.2151 11.1608 14.9737L18.6067 6.70013Z"
        fill="#007385"
        fill-rule="evenodd"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "ConfirmedIcon",
};
</script>
