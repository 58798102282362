<template>
  <section class="course-recommended" v-if="courses && courses.length">
    <div class="container course-recommended__container">
      <h2 class="course-recommended__title">Похожие курсы</h2>
      <div class="course-recommended__list">
        <CourseComponent v-for="(course, i) in courses" :key="i" :course="course" />
      </div>
    </div>
  </section>
</template>

<script>
import CourseComponent from "components/CourseComponent.vue";

export default {
  name: "CourseRecommendedComponent",
  computed: {
    courses() {
      return this.$store.state.course_page.similar_courses;
    },
  },
  components: { CourseComponent },
};
</script>

<style lang="stylus">
.course-recommended {
  padding 0 32px
  +below(640px) {
    padding 0 20px
  }

  &__container {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    width 100%
    gap 30px
  }

  &__title {
    font-weight: bold;
    font-size: 3em;
    line-height: 58px;
    margin 0
    +below(1120px) {
      font-size: 2.75em
      line-height: normal;
    }
    +below(640px) {
      font-size: 1.875em
      line-height: 36px;
    }
  }

  &__list {
    display grid
    grid-template-columns repeat(4, 1fr)
    width 100%
    grid-gap 20px
    +below(1180px) {
      grid-template-columns repeat(3, 1fr)
      .course__item:nth-child(4) {
        display none
      }
    }
    +below(840px) {
      grid-template-columns repeat(2, 1fr)
      .course__item:nth-child(4) {
        display flex
      }
    }
    +below(560px) {
      grid-template-columns 1fr
    }
  }
}
</style>
