<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.065 12.1191H11.415"
        stroke="#007385"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0166 20.5166C16.9872 20.5166 21.0166 16.4872 21.0166 11.5166C21.0166 6.54604 16.9872 2.5166 12.0166 2.5166C7.04604 2.5166 3.0166 6.54604 3.0166 11.5166C3.0166 16.4872 7.04604 20.5166 12.0166 20.5166Z"
        stroke="#007385"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.415 12.1189V6.5166"
        stroke="#007385"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "TimeClockIcon",
};
</script>
