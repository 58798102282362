var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.course)?_c('section',{staticClass:"course-welcome"},[_c('div',{staticClass:"container"},[_c('BreadcrumbsComponent',{attrs:{"parent-pages":_vm.parentPages,"title":_vm.course.title}}),_c('div',{staticClass:"course-welcome__container"},[_c('div',{staticClass:"course-welcome__content"},[(_vm.course.school)?_c('div',{staticClass:"course-welcome__author"},[_c('img',{staticClass:"course-welcome__author-image",attrs:{"src":_vm._f("image")(_vm.course.school.head_img,_vm.$store.state._env.MEDIA_ENDPOINT),"alt":_vm._f("image_alt")(_vm.course.school.head_img)}}),_c('div',{staticClass:"course-welcome__author-name"},[_vm._v("\n            "+_vm._s(_vm.course.school.title)+"\n          ")])]):_vm._e(),_c('h1',{staticClass:"course-welcome__title"},[_vm._v(_vm._s(_vm.course.title))]),_c('p',{staticClass:"course-welcome__description"},[_vm._v(_vm._s(_vm.course.short_text))]),_c('div',{staticClass:"course-welcome__bottom"},[(_vm.user && _vm.user.courses_ids.includes(_vm.course.id))?_c('router-link',{staticClass:"btn btn--medium btn--main",attrs:{"to":{
              name: 'account_education_list',
              params: { id: this.course.id },
            }}},[_vm._v("\n            Перейти к курсу\n          ")]):(_vm.course.price !== null)?_c('button',{staticClass:"btn btn--medium btn--main",attrs:{"type":"button"},on:{"click":_vm.buyCourse}},[_vm._v("\n            Купить -\n            "+_vm._s(_vm.course.price.toLocaleString("ru-RU", {
                maximumFractionDigits: 0,
              }))+"₽\n          ")]):_c('button',{staticClass:"btn btn--medium btn--main",attrs:{"type":"button"},on:{"click":_vm.buyCourse}},[_vm._v("\n            Купить - Бесплатно\n          ")]),(_vm.course.reviews_avg_mark)?_c('div',{staticClass:"course-welcome__rating"},[_c('span',{staticClass:"course-welcome__rating-number"},[_vm._v("\n              "+_vm._s(_vm.course.reviews_avg_mark.toFixed(1) || "-")+"\n            ")]),_c('div',{staticClass:"course-welcome__rating-stars"},_vm._l((5),function(i){return _c('StarIcon',{key:i,class:{
                  inactive: _vm.course.reviews_avg_mark.toFixed(1) < i - 0.5,
                }})}),1),_c('span',{staticClass:"course-welcome__rating-reviews"},[_vm._v("\n              (\n              "+_vm._s(_vm._f("plural")(_vm.course.reviews.length,"оценка", "оценки", "оценок"))+"\n              )\n            ")])]):_vm._e()],1)]),_c('div',{staticClass:"course-welcome__image"},[_c('img',{attrs:{"src":_vm._f("image")(_vm.course.splash_img,_vm.$store.state._env.MEDIA_ENDPOINT),"alt":_vm._f("image_alt")(_vm.course.splash_img)}})])])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }