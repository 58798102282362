<template>
  <section class="course-program" v-if="course">
    <div class="container course-program__container">
      <div class="course-program__header">
        <h2 class="course-program__title">Программа курса</h2>
        <div class="course-program__stats">
          <div class="course-program__stat">
            <FolderBlankIcon />
            <div class="course-program__stat-text">
              <b>{{ course.modules_count }}</b>
              <span>{{ course.modules_count | plural_word("раздел", "раздела", "разделов") }}</span>
            </div>
          </div>
          <div class="course-program__stat">
            <BrowserWebPlayIcon />
            <div class="course-program__stat-text">
              <b>{{ course.lessons_count }}</b>
              <span>{{ course.lessons_count | plural_word("лекция", "лекции", "лекций") }}</span>
            </div>
          </div>
          <div class="course-program__stat">
            <TimeClockIcon />
            <div class="course-program__stat-text">
              <span>более</span>
              <b>{{ course.hours_count | plural("часа", "часов", "часов") }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="course-program__modules" v-if="course.modules.length">
        <div
          :class="{ 'module--active': isActive(i) }"
          class="module"
          v-for="(module, i) in course.modules"
          :key="i"
        >
          <a class="module__header" @click="setActive(i)">
            <span class="module__header-title">{{ module.title }}</span>
            <div class="module__header-right">
              <span class="module__header-count">{{ module.lessons.length }} уроков</span>
              <span class="module__header-icon">
                <CrosshairIcon />
              </span>
            </div>
          </a>
          <div class="module__lessons">
            <div class="module__lesson" v-for="(lesson, i) in module.lessons" :key="i">
              <div class="module__lesson-title">{{ i + 1 }}. {{ lesson.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CrosshairIcon from "components/svg/CrosshairIcon.vue";
import FolderBlankIcon from "components/svg/FolderBlankIcon.vue";
import BrowserWebPlayIcon from "components/svg/BrowserWebPlayIcon.vue";
import TimeClockIcon from "components/svg/TimeClockIcon.vue";

export default {
  name: "CourseProgramComponent",
  data() {
    return {
      activeId: null,
    };
  },
  computed: {
    course() {
      return this.$store.state.course_page.course;
    },
  },
  methods: {
    setActive(id) {
      if (id === this.activeId) {
        this.activeId = null;
      } else {
        this.activeId = id;
      }
    },
    isActive(id) {
      return this.activeId === id;
    },
  },
  components: {
    TimeClockIcon,
    BrowserWebPlayIcon,
    FolderBlankIcon,
    CrosshairIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.course-program {
  padding 0 32px
  +below(640px) {
    padding 0 20px
  }

  &__container {
    border 1px solid var(--border_color)
    border-radius: 15px;
    +below(640px) {
      border none
      margin 0 -20px
    }
  }

  &__title {
    font-weight: bold;
    font-size: 3em;
    line-height: 58px;
    margin 0
    +below(1120px) {
      font-size: 2.75em
      line-height: normal;
    }
    +below(640px) {
      font-size: 1.875em
      line-height: 36px;
    }
  }

  &__header {
    display flex
    align-items center
    flex-wrap wrap
    gap 40px
    justify-content space-between
    padding 50px 50px 56px
    border-bottom 1px solid var(--border_color)
    +below(640px) {
      gap 30px
      padding 0 20px 30px
    }
  }

  &__stats {
    display flex
    align-items center
    gap 30px
    +below(640px) {
      gap 24px
    }
  }

  &__stat {
    display flex
    align-items center
    gap 10px
    +below(680px) {
      flex-direction column
      align-items flex-start
      justify-content flex-start
    }

    &-text {
      display flex
      align-items center
      gap 3px
      font-weight: 500;
      font-size: 1.125em;
      line-height: 140%;
      color: var(--black);
      +below(680px) {
        flex-direction column
        align-items flex-start
        justify-content flex-start
        gap 0
      }
    }

    .icon {
      width 24px
      height 24px
      flex-shrink 0

      svg {
        width 100%
        height: 100%;
        svg(var(--main_color))
      }
    }

    b {
      color: var(--main_color);
    }
  }

  &__modules {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    width 100%
  }
}

.module {
  width 100%
  border-bottom 1px solid var(--border_color)

  &:last-child {
    border-bottom: none
  }

  &--active {
    & ^[0]__lessons {
      visibility: visible;
      height: auto;
      overflow: visible;
      opacity: 1;
      padding-bottom: 30px;
    }

    & ^[0]__header-icon {
      transform rotate(45deg)
    }
  }

  &__header {
    padding 30px 50px
    display flex
    align-items center
    justify-content space-between
    +below(640px) {
      padding 30px 20px
    }

    &:hover {
      color var(--main_color)

      svg path {
        stroke var(--main_color)
      }
    }

    &-title {
      font-weight: 500;
      font-size: 1.25em
      line-height: 34px;
    }

    &-right {
      display flex
      align-items center
      gap 40px
    }

    &-count {
      padding 5px 10px
      border-radius: 5px;
      background-color var(--main_color)
      color var(--white)
      +below(640px) {
        display none
      }
    }

    &-icon {
      display flex
      align-items center
      transition var(--transition)

      svg {
        width 20px
        height: 20px
      }
    }
  }

  &__lessons {
    padding 0 50px
    display grid
    grid-gap 20px
    visibility: hidden;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all var(--transition)
    +below(640px) {
      padding 0 20px
    }
  }
}
</style>
