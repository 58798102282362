<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H19C20.105 3 21 3.895 21 5V10"
        stroke="#007385"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M3 8H21" stroke="#007385" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M22 17C22 19.762 19.762 22 17 22C14.238 22 12 19.762 12 17C12 14.238 14.238 12 17 12C19.762 12 22 14.238 22 17Z"
        stroke="#007385"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.2807 15.0024L18.9167 16.5614C19.2497 16.7584 19.2497 17.2414 18.9167 17.4384L16.2807 18.9974C15.9407 19.1984 15.5117 18.9534 15.5117 18.5584V15.4414C15.5117 15.0464 15.9407 14.8014 16.2807 15.0024Z"
        stroke="#007385"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "BrowserWebPlayIcon",
};
</script>
