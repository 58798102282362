<template>
  <div class="breadcrumb">
    <ul class="breadcrumb__list">
      <li :key="index" v-for="(parent, index) in parents">
        <router-link :to="{ name: parent.name, params: parent.params }" class="breadcrumb__link">
          <span>{{ parent.title }}</span>
          <BreadcrumbArrowIcon />
        </router-link>
      </li>
      <li v-if="title">
        <span>{{ title }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import BreadcrumbArrowIcon from "components/svg/BreadcrumbArrowIcon.vue";

export default {
  name: "BreadcrumbsComponent",
  components: { BreadcrumbArrowIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    parentPages: {
      type: Array,
      required: false,
    },
  },
  computed: {
    parents() {
      return [this.$route.meta.parent, this.parentPages || []].flat();
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.breadcrumb {
  display flex
  flex-direction column
  align-items flex-start
  justify-content flex-start
  width 100%

  &__list {
    display flex
    align-items center
    justify-content flex-start
    flex-wrap wrap
    font-weight: normal;
    font-size: 0.875em
    line-height: 20px
    list-style none
    gap 6px
    +below(530px) {
      align-items flex-start
    }

    .icon {
      width: 18px;
      height 18px

      svg {
        width 100%
        height 100%
        svg(var(--white))
      }
    }

    li {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
    }
  }

  &__link {
    text-decoration none
    transition var(--transition)
    color: var(--black);
    position relative
    display flex
    font-weight normal
    align-items center
    gap 6px

    +below(530px) {
      display flex
    }

    &:hover {
      text-decoration underline
    }
  }

  &__title {
    margin 0 0 50px
    font-weight: bold;
    font-size: 1.375em;
    line-height: 26px
    text-transform: uppercase;
    color: var(--black);
  }
}
</style>
