<template>
  <section class="course-welcome" v-if="course">
    <div class="container">
      <BreadcrumbsComponent :parent-pages="parentPages" :title="course.title" />
      <div class="course-welcome__container">
        <div class="course-welcome__content">
          <div class="course-welcome__author" v-if="course.school">
            <img
              :src="course.school.head_img | image($store.state._env.MEDIA_ENDPOINT)"
              class="course-welcome__author-image"
              :alt="course.school.head_img | image_alt"
            />
            <div class="course-welcome__author-name">
              {{ course.school.title }}
            </div>
          </div>
          <h1 class="course-welcome__title">{{ course.title }}</h1>
          <p class="course-welcome__description">{{ course.short_text }}</p>
          <div class="course-welcome__bottom">
            <router-link
              class="btn btn--medium btn--main"
              v-if="user && user.courses_ids.includes(course.id)"
              :to="{
                name: 'account_education_list',
                params: { id: this.course.id },
              }"
            >
              Перейти к курсу
            </router-link>
            <button
              class="btn btn--medium btn--main"
              type="button"
              v-else-if="course.price !== null"
              @click="buyCourse"
            >
              Купить -
              {{
                course.price.toLocaleString("ru-RU", {
                  maximumFractionDigits: 0,
                })
              }}₽
            </button>
            <button class="btn btn--medium btn--main" type="button" v-else @click="buyCourse">
              Купить - Бесплатно
            </button>
            <div class="course-welcome__rating" v-if="course.reviews_avg_mark">
              <span class="course-welcome__rating-number">
                {{ course.reviews_avg_mark.toFixed(1) || "-" }}
              </span>
              <div class="course-welcome__rating-stars">
                <StarIcon
                  v-for="i in 5"
                  :key="i"
                  :class="{
                    inactive: course.reviews_avg_mark.toFixed(1) < i - 0.5,
                  }"
                />
              </div>
              <span class="course-welcome__rating-reviews">
                (
                {{ course.reviews.length | plural("оценка", "оценки", "оценок") }}
                )
              </span>
            </div>
          </div>
        </div>
        <div class="course-welcome__image">
          <img
            :src="course.splash_img | image($store.state._env.MEDIA_ENDPOINT)"
            :alt="course.splash_img | image_alt"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import StarIcon from "components/svg/StarIcon.vue";
import COURSE_BUY from "@/graphql/mutations/CourseBuy.graphql";
import AlertModal from "components/modals/components/AlertModal.vue";
import LoginModal from "components/modals/components/LoginModal.vue";

export default {
  name: "CourseWelcomeComponent",
  computed: {
    parentPages() {
      return [
        {
          title: this.course.category.title,
          name: "course_category",
          params: { link: this.course.category.link },
        },
      ];
    },
    user() {
      return this.$store.state.auth.user;
    },
    course() {
      return this.$store.state.course_page.course;
    },
  },
  methods: {
    buyCourse() {
      let token = this.$store.state.cookies["apollo-token"];
      if (this.$store.state.auth.user && token) {
        this.$apollo
          .mutate({
            mutation: COURSE_BUY,
            variables: {
              course_id: this.course.id,
            },
            context: {
              headers: {
                Authorization: "Bearer " + token,
              },
            },
          })
          .then(({ data }) => {
            if (data && data.CourseBuy) {
              if (data.CourseBuy.payment_url) {
                let link = document.createElement("a");
                link.href = data.CourseBuy.payment_url;
                link.click();
              } else {
                if (this.$store.state.auth.user.courses_ids) {
                  this.$store.state.auth.user.courses_ids.push(this.course.id);
                } else {
                  this.$store.state.auth.user.courses_ids = [this.course.id];
                }
                this.$store.state._modals.push({
                  component: AlertModal,
                  options: {
                    type: "success",
                    title: data.CourseBuy.title,
                    subtitle: data.CourseBuy.message,
                    buttonTitle: "Перейти к курсу",
                    callback: () => {
                      this.$router.push({
                        name: "account_education_list",
                        params: { id: this.course.id },
                      });
                    },
                  },
                });
              }
            }
          })
          .catch(() => {});
      } else {
        this.$store.state._modals.push({
          component: LoginModal,
          options: {
            routeAfterLogin: {
              name: this.$route.name,
              params: this.$route.params,
              hash: this.$route.hash,
            },
          },
        });
      }
    },
  },
  components: {
    StarIcon,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
@import '~@/styles/mixins/svg.styl';

.course-welcome {
  padding: 0 32px 70px;
  color: var(--white);
  background: #101010;

  +below(640px) {
    padding: 0 20px 20px;
  }

  .breadcrumb__link {
    color: var(--white);
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    +below(926px) {
      grid-template-columns: 1fr;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__author {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 70px;
    margin-bottom: 40px;

    +below(1120px) {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    +below(926px) {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    &-image {
      width: 50px;
      height: 50px;
      object-fit: contain;
      object-position: center;
      border-radius: 50%;
      background: var(--black);
    }

    &-name {
      font-weight: 500;
      font-size: 1.125em;
      line-height: 120%;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 3.75em;
    line-height: 73px;
    margin: 0;

    +below(1120px) {
      font-size: 2.75em;
      line-height: normal;
    }

    +below(540px) {
      font-size: 1.875em;
      line-height: 36px;
    }
  }

  &__description {
    font-size: 1.5em;
    line-height: 140%;
    margin-top: 30px;
    margin-bottom: 40px;

    +below(1120px) {
      font-size: 1.2em;
    }

    +below(926px) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    +below(540px) {
      font-size: 1em;
      line-height: 140%;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 20px;

    +below(540px) {
      align-items: flex-start;
      flex-direction: column-reverse;
      width: 100%;
    }

    .btn {
      white-space: nowrap;
      padding: 20px 50px;

      +below(540px) {
        width: 100%;
      }
    }
  }

  &__rating {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    &-number {
      font-weight: bold;
      font-size: 0.875em;
      line-height: 17px;
      color: var(--white);
    }

    &-stars {
      display: flex;
      align-items: center;
      gap: 5px;

      .icon {
        flex-shrink: 0;
        width: 20px;
        height: 20px;

        &.inactive svg {
          svg(var(--gray));
        }

        svg {
          width: 100%;
          height: 100%;
          svg(var(--orange));
        }
      }
    }

    &-reviews {
      font-size: 0.875em;
      line-height: 17px;
      color: var(--white);
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      absolute: right top bottom left;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: right top;
    }
  }

  .breadcrumb {
    padding-top: 30px;

    +below(540px) {
      padding-top: 20px;
    }
  }
}
</style>
