<template>
  <section class="course-bnf" v-if="course && (benefits.length || skills.length)">
    <div class="container">
      <div class="course-bnf__container">
        <div class="course-bnf__content" v-if="course && benefits.length">
          <h2 class="course-bnf__title">Чему вы научитесь</h2>
          <ul class="course-bnf__list">
            <li class="course-bnf__item" v-for="(benefit, i) in benefits" :key="i">
              <ConfirmedIcon />
              <span>{{ benefit.title }}</span>
            </li>
          </ul>
        </div>
        <div class="course-skills" v-if="course && skills.length">
          <h3 class="course-skills__title">Навыки которые вы получите</h3>
          <ul class="course-skills__list">
            <li class="course-skills__item" v-for="(skill, i) in skills" :key="i">{{ skill.title }}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ConfirmedIcon from "components/svg/ConfirmedIcon.vue";

export default {
  name: "CourseBenefitsComponent",
  computed: {
    benefits() {
      return this.course.additionals.filter((additional) => {
        return additional.type.code === this.$store.state._types.CODE_ADDITIONALS_WHAT_LEARN;
      });
    },
    skills() {
      return this.course.additionals.filter((additional) => {
        return additional.type.code === this.$store.state._types.CODE_ADDITIONALS_SKILLS;
      });
    },
    course() {
      return this.$store.state.course_page.course;
    },
  },
  components: { ConfirmedIcon },
};
</script>

<style lang="stylus">
.course-bnf {
  margin-bottom 10px
  padding 0 32px
  +below(640px) {
    padding 0 20px
  }

  &__container {
    display grid
    grid-template-columns 8fr 4fr
    align-items: start;
    justify-content space-between
    grid-gap 140px
    +below(1240px) {
      grid-gap 20px
    }
    +below(840px) {
      grid-gap 30px
      grid-template-columns 1fr
    }
  }

  &__title {
    font-weight: bold;
    font-size: 3em;
    line-height: 58px;
    margin 0
    +below(1120px) {
      font-size: 2.75em
      line-height: normal;
    }
    +below(540px) {
      font-size: 1.875em
      line-height: 36px;
    }
  }

  &__content {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 50px
    +below(1120px) {
      gap 30px
    }
  }

  &__list {
    display grid
    grid-template-columns auto auto
    grid-gap 40px
    justify-content space-between
    width 100%
    +below(1120px) {
      grid-gap 20px
    }
    +below(840px) {
      grid-template-columns 1fr
    }
  }

  &__item {
    max-width 345px
    width 100%
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 15px
    +below(768px) {
      max-width 100%
    }

    .icon {
      width 22px
      height 22px

      svg {
        width 100%
        height 100%
      }
    }

    span {
      font-weight: normal;
      font-size: 1.25em;
      line-height: 140%;
      +below(1120px) {
        font-size: 1.125em;
        line-height: 140%;
      }
      +below(540px) {
        font-size: 1em;
        line-height: 140%;
      }
    }
  }
}

.course-skills {
  background: var(--white);
  box-shadow: 0 0 50px var(--black_o08);
  border-radius: 15px;
  padding 30px
  gap 20px
  display flex
  flex-direction column

  &__title {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 140%;
    margin 0
    +below(1120px) {
      font-size: 1.25em;
      line-height: 140%;
    }
    +below(540px) {
      font-size: 1.125em;
      line-height: 140%;
    }
  }

  &__list {
    display flex
    flex-wrap wrap
    align-items flex-start
    justify-content flex-start
    width 100%
    gap 5px
  }

  &__item {
    background: var(--gray_light);
    border-radius: 5px;
    padding 5px 10px
    font-size: 0.875em;
    line-height: 140%;
  }
}
</style>
